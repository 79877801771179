<template>
  <div>
    <div class="flex flex-wrap">
      <div
        class="mt-2 w-full sm:w-1/2"
        v-if="device.properties.find((p) => p.property_type_id === 3000)?.value_boolean"
      >
        <span class="font-bold">Gesamt:</span>
        {{ device.mileage?.toLocaleString('de-de') }}km
      </div>
      <div class="mt-2 w-full sm:w-1/2" v-if="device.operating_hours" @click="popupOperatingHours.toggle">
        <span class="font-bold">Betriebsstunden:</span>
        {{ device.operating_hours.toLocaleString('de-de') }}h
        <button
          class="cursor-pointer text-sm font-light text-gray-700 hover:text-secondary"
          v-if="useRightsStore().isAllowed('UPDATE', 'OPERATINGHOURS')"
        >
          (bearbeiten)
        </button>
        <OverlayPanel ref="popupOperatingHours" showCloseIcon dismissable>
          <OperatingHoursAdmin :deviceId="device.id" class="!bg-white !p-2" />
        </OverlayPanel>
      </div>
      <div
        class="mt-2 w-full sm:w-1/2"
        v-for="property in device.properties.filter(p => !devicePropertiesWithFixedPositions.includes(p.property_type_id!) && p.property_type_id < 3000)"
      >
        <!-- or show empty if no value -->
        <span class="font-bold">{{ propertyStore.propertyTypeLabelById(property.property_type_id) }}:</span>
        {{ getFormattedValue(property) }}
      </div>
    </div>
    <div
      class="mt-4 w-full"
      v-if="device.properties.findIndex((p) => p.property_type_id === devicePropertyCommentTpyeId) >= 0"
    >
      <span class="mb-1 font-bold">{{ propertyStore.propertyTypeLabelById(devicePropertyCommentTpyeId) }}:</span>
      <pre class="text-wrap font-sans">{{
        getFormattedValue(device.properties.find((p) => p.property_type_id === devicePropertyCommentTpyeId))
      }}</pre>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { devicePropertiesWithFixedPositions, devicePropertyCommentTpyeId } from '@/consts'
  import { getFormattedValue } from '@/helper/property'
  import { usePropertyStore } from '@/store/property'
  import { DeviceWithProperties } from '@/types/extendDatabase'
  import { ref } from 'vue'
  import OperatingHoursAdmin from '@/components/OperatingHoursAdmin.vue'
  import { useRightsStore } from '@/store/rights'

  const props = defineProps<{
    device: DeviceWithProperties
  }>()

  const propertyStore = usePropertyStore()

  const popupOperatingHours = ref()
</script>
