<template>
  <div
    class="flow-row flex justify-between border-b border-gray-200 px-5 py-2 font-medium"
    :class="{
      ' border-t-1 border-b-0 border-solid border-red-900': isActive,
      'cursor-pointer': !isActive,
    }"
  >
    <div v-if="device.asset_type === PRESS_TYPE" class="flex">
      <div class="h-5 w-5" v-if="!onlyList">
        <div v-if="device.press_state" class="size-full">
          <img v-if="device.press_state.level === 1" src="@/assets/trash_75.png" />
          <img v-else-if="device.press_state.level === 2" src="@/assets/trash_100.png" />
          <i
            v-else-if="device.press_state.state && device.press_state.state != 10"
            class="pi pi-exclamation-triangle size-full text-center !text-base text-red-700"
          />
        </div>
      </div>
      <div class="ml-3 text-sm" :class="{ 'font-bold': isActive }">{{ device.name }}</div>
    </div>
    <div v-else class="flex">
      <div
        class="h-5 w-5 border"
        :class="[classCircleStyle, isBleType ? 'rounded' : 'rounded-full']"
        v-if="!onlyList"
      ></div>
      <div class="ml-3 text-sm" :class="{ 'font-bold': isActive }">{{ device.name }}</div>
    </div>
    <XIcon v-if="isActive" class="w-3 cursor-pointer" :class="{ '!w-5': isSmallScreen }" @click.stop="$emit('close')" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { IGNITION_OFF, IGNITION_ON, IGNITION_ON_AND_MOVEMENT } from '@/consts'
  import { XIcon } from '@heroicons/vue/solid'
  import { DeviceWithPropertiesAndOptionalPressState } from '@/types/extendDatabase'
  import { useUserDeviceStore } from '@/store/userDevice'
  import { BLE_TYPE, PRESS_TYPE } from '@/helper/filterAndSorting'

  export default defineComponent({
    components: { XIcon },
    props: {
      device: {
        type: Object as () => DeviceWithPropertiesAndOptionalPressState,
        required: true,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      onlyList: {
        type: Boolean,
        default: false,
      },
      onlyListPosition: {
        type: Number,
        default: 0,
      },
    },
    emits: ['close'],
    setup(props) {
      const isSmallScreen = computed(() => useUserDeviceStore().isSmallScreen)

      const classCircleStyle = ref('')

      function setStyle() {
        if (props.device.asset_type === PRESS_TYPE) return
        if (props.device.asset_type === BLE_TYPE) {
          classCircleStyle.value = 'bg-gray-300 border-gray-300'
          return
        }
        switch (props.device.state) {
          case IGNITION_OFF:
            classCircleStyle.value = 'bg-ignition-off border-red-700'
            break
          case IGNITION_ON:
            classCircleStyle.value = 'bg-ignition-on border-yellow-600'
            break
          case IGNITION_ON_AND_MOVEMENT:
            classCircleStyle.value = 'bg-ignition-on-and-moving border-green-900'
            break
          default:
            classCircleStyle.value = 'bg-gray-300 border-gray-30'
        }
      }
      setStyle()

      return {
        classCircleStyle,
        props,
        isBleType: props.device.asset_type === BLE_TYPE,
        isSmallScreen,
        PRESS_TYPE,
      }
    },
  })
</script>
