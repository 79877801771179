<template>
  <div class="flex flex-col justify-between rounded-md border-2 border-gray-400 p-1">
    <ul>
      <li class="flex rounded-md px-1" :class="status.class">
        <div class="w-20 shrink-0">Status:</div>
        {{ status.status }}
      </li>
      <li v-if="!small" class="flex px-1">
        <div class="w-20 shrink-0">Gerät:</div>
        {{ device ? device.name : 'Gerät nicht gefunden' }}
      </li>
      <li class="flex px-1">
        <div class="w-20 shrink-0">Datum:</div>
        {{ formatDate(new Date(protocol.created_at)) }}
      </li>
      <li v-if="!small" class="flex px-1">
        <div class="w-20 shrink-0">Prot.-Art:</div>
        {{ protocol.label }}
      </li>
    </ul>
    <div class="flex w-full gap-2">
      <Button
        v-if="status.status !== 'Offen' && rightsStore.isAllowed('READ', 'MAINTENANCE')"
        label="Anschauen"
        @click="emits('editButtonClicked')"
        class="flex-1"
      />
      <Button
        v-if="status.status === 'Offen' && rightsStore.isAllowed('UPDATE', 'MAINTENANCE')"
        label="Weiterbearbeiten"
        @click="emits('editButtonClicked')"
        class="flex-1"
      />
      <Button
        v-if="useNewButton && rightsStore.isAllowed('CREATE', 'MAINTENANCE')"
        :label="status.status === 'Offen' ? 'Neu anlegen' : 'Folgeprotokoll anlegen'"
        @click="emits('newButtonClicked')"
        class="flex-1"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { MaintenanceProtocol } from '@/types/extendDatabase'
  import { formatDate } from '@/helper/date'
  import { useDeviceStore } from '@/store/device'
  import { computed } from 'vue'
  import { useRightsStore } from '@/store/rights'
  import { stat } from 'fs'

  const props = defineProps({
    protocol: {
      type: Object as () => MaintenanceProtocol,
      required: true,
    },
    useNewButton: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  })

  const rightsStore = useRightsStore()

  const emits = defineEmits<{
    editButtonClicked: []
    newButtonClicked: []
  }>()

  let device = computed(() => useDeviceStore().getDeviceById(props.protocol.asset))

  let status = computed(() => {
    switch (props.protocol.status) {
      case 1:
        return { class: 'bg-yellow-200', status: 'Offen' }
      case 2:
        return { class: 'bg-yellow-200', status: 'Offen' }
      case 3:
        return { class: 'bg-gray-200', status: 'Abgebrochen' }
      case 4:
        return { class: 'bg-gray-200 line-through', status: 'Abgebrochen' }
      case 5:
        return { class: 'bg-green-300', status: 'Bestanden' }
      case 6:
        return { class: 'bg-green-300 line-through', status: 'Bestanden' }
      case 7:
        return { class: 'bg-red-300', status: 'Nicht bestanden' }
      case 8:
        return { class: 'bg-red-300 line-through', status: 'Nicht bestanden' }
      default:
        return { class: 'bg-gray-400', status: 'Unbekannter Status' }
    }
  })
</script>

<style scoped></style>
