import { prepareDevice } from '@/helper/deviceHelper'
import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { DeviceWithProperties, DeviceWithPropertiesRaw } from '@/types/extendDatabase'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'
import { useRightsStore } from './rights'

export const useDeviceStore = defineStore(
  'deviceStore',
  () => {
    let devices: Ref<DeviceWithProperties[]> = ref([])
    const indexes: Map<number, number> = new Map()
    let deviceUpdates: any = null
    let updatedDevices: Ref<number[]> = ref([])

    const rightsStore = useRightsStore()

    async function fetchDevices() {
      try {
        const { data, error } = await supabase
          .from('g4y_device')
          .select('*, properties:device_property(*)')
          .eq('properties.overridden', false)
          .returns<DeviceWithPropertiesRaw[]>()
        if (error) throw error
        devices.value = data.map((d) => prepareDevice(d))
        indexes.clear()
      } catch (error) {
        console.error(error)
      }
    }

    async function updateDeviceFromServerById(id: number) {
      try {
        const { data, error } = await supabase
          .from('g4y_device')
          .select('*, properties:device_property(*)')
          .eq('properties.overridden', false)
          .eq('id', id)
          .returns<DeviceWithPropertiesRaw[]>()
          .single()
        if (error) throw error
        updateDeviceWithProperties(prepareDevice(data))
      } catch (error) {
        console.error(error)
      }
    }

    function sortDevices(a: DeviceWithProperties, b: DeviceWithProperties) {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()
      if (aName < bName) {
        return -1
      } else if (aName > bName) {
        return 1
      } else {
        return 0
      }
    }

    ;(() => {
      fetchDevices()
    })()

    const active = computed(() => {
      if (rightsStore.isAllowed('READ', 'INACTIVE')) {
        return devices.value.sort(sortDevices).map((d) => {
          const pre = '(INAKTIV) '
          if (!d.name.startsWith(pre) && !d.active) d.name = pre + d.name
          return d
        })
      } else return devices.value.filter((d) => d.active).sort(sortDevices)
    })

    const all = computed(() => {
      return devices.value.sort(sortDevices)
    })

    function getIndexById(id: number) {
      let index = indexes.get(id)
      if (index && devices.value[index]?.id != id) {
        //FIXME: find reason for invalid / duplicate Keys
        indexes.clear()
        index = undefined
      }
      if (index === undefined || index === -1) {
        const newIndex = devices.value.findIndex((d) => d.id === id)
        indexes.set(id, newIndex)
        index = newIndex
      }
      return index
    }

    function updateDeviceWithProperties(device: DeviceWithProperties) {
      const index = getIndexById(device.id)
      if (index > -1) {
        devices.value[index] = device
      } else {
        devices.value.push(device)
      }
      updatedDevices.value.push(device.id)
    }

    function updateDeviceWithoutProperties(device: DeviceWithPropertiesRaw) {
      const index = getIndexById(device.id)
      const oldProps = devices.value[index].properties
      const newDevice: DeviceWithProperties = prepareDevice({ ...device, properties: oldProps })
      updateDeviceWithProperties(newDevice)
    }

    //TODO disable subscription on mobile?
    async function subscribeToDeviceChanges() {
      if (deviceUpdates != null) return
      deviceUpdates = supabase
        .channel('pulic:g4y_device')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'g4y_device' }, (payload) => {
          updateDeviceWithoutProperties(<DeviceWithPropertiesRaw>payload.new)
        })
        .subscribe()
    }

    async function unsubscribeFromDeviceChanges() {
      if (deviceUpdates === null) return
      const result = await supabase.removeChannel(deviceUpdates)
      if (result === 'ok') deviceUpdates === null
    }

    const getDeviceById = computed(() => {
      return (id: number): DeviceWithProperties | null => {
        const index = getIndexById(id)
        if (index > -1) {
          return devices.value[index]
        } else {
          return null
        }
      }
    })

    const getDevicesWithProperty = computed(() => {
      return (propertyTypeId: number): DeviceWithProperties[] => {
        return devices.value.filter((d) =>
          d.properties.some((p) => p.property_type_id === propertyTypeId && p.value_boolean === true)
        )
      }
    })

    return {
      devices,
      updatedDevices,
      all,
      active,
      subscribeToDeviceChanges,
      unsubscribeFromDeviceChanges,
      getDeviceById,
      getDevicesWithProperty,
      updateDeviceFromServerById,
      fetchDevices,
    }
  },
  { persist: false }
)
