<template>
  <div>
    <Dialog header="Datei löschen" v-model:visible="askDelete" modal :closable="false">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3 text-[color:var(--delete-color)]" style="font-size: 2rem" />
        <span class="">Soll die Datei wirklich gelöscht werden?</span>
      </div>
      <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" @click="askDelete = false" />
        <Button label="Löschen" icon="pi pi-trash" @click="deleteFile(selectedFileId)" severity="danger" outlined />
      </template>
    </Dialog>
    <div v-if="isLoading" class="flex h-[460px] flex-col justify-around">
      <div class="flex flex-col text-center">
        <ProgressSpinner />
        <span class="mt-2">Dateien werden geladen .... </span>
      </div>
    </div>
    <div v-if="files.length > 0 && selectedFileId != 0">
      <div class="mt-3 space-y-2">
        <div v-for="file in files" :key="file.id" class="flex">
          <Button
            size="small"
            :label="file.name"
            icon="pi pi-file-pdf"
            outlined
            iconPos="right"
            @click="openFile(file.id)"
          />
          <i
            class="pi pi-trash my-auto ml-2 cursor-pointer text-red-400 hover:text-red-500"
            @click="enterDelete(file.id)"
            v-if="rightsStore.isAllowed('DELETE', 'DEVICE.PDF') && edit"
          />
        </div>
      </div>
    </div>
    <div class="mt-5 overflow-auto" v-if="edit">
      <div v-if="isUploading" class="flex w-full flex-col">
        <div class="mb-1">Datei wird hochgeladen ...</div>
        <ProgressBar mode="indeterminate" />
      </div>

      <div v-else-if="rightsStore.isAllowed('CREATE', 'DEVICE.PDF')" class="float-right inline-flex">
        <FileUpload
          mode="basic"
          auto
          customUpload
          @uploader="uploadFile"
          chooseIcon=""
          chooseLabel="Datei hinzufügen"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DeviceFile, getFilesForDevice } from '@/helper/storage'
  import { supabase } from '@/supabase'
  import { Ref, ref } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useRouter } from 'vue-router'
  import { FileUploadUploaderEvent } from 'primevue/fileupload'
  import { useRightsStore } from '@/store/rights'

  const props = defineProps({
    deviceId: { type: String, required: true },
    edit: { type: Boolean, default: false },
  })
  const toast = useToast()
  const router = useRouter()
  const rightsStore = useRightsStore()

  let isLoading = ref(true)
  let files: Ref<DeviceFile[]> = ref([])
  let selectedFileId = ref(0)
  let isUploading = ref(false)
  const askDelete = ref(false)

  getFilesForDevice(parseInt(props.deviceId)).then((pics) => {
    files.value.push(...pics)
    if (files.value.length > 0) selectedFileId.value = files.value[0].id
    isLoading.value = false
  })

  const uploadFile = async (e: FileUploadUploaderEvent) => {
    isUploading.value = true
    //@ts-ignore
    const file = <File>e.files[0]
    const fileExt = file.name.split('.').pop()
    if (files.value.find((f) => f.name === file.name)) return //TODO add error msg
    const fileName = `${props.deviceId}${Math.random()}.${fileExt}`
    const { error: uploadError } = await supabase.storage.from('device-file').upload(fileName, file)
    if (uploadError) throw uploadError
    try {
      const { data, error } = await supabase
        .from('g4y_device_file')
        .insert({ device_id: parseInt(props.deviceId), url: fileName, name: file.name })
        .select()
        .single()
      if (error || data === null) throw error
      const newFileObject = data
      files.value.push({
        id: newFileObject.id,
        url: URL.createObjectURL(file),
        urlInStorage: fileName,
        name: file.name,
      })
      selectedFileId.value = newFileObject.id
    } catch (error) {
      console.trace(error)
    } finally {
      isUploading.value = false
    }
  }

  const deleteFile = async (id: number) => {
    askDelete.value = false
    try {
      const file = files.value.find((p) => p.id === id)
      if (file && file.urlInStorage) {
        //TODO: remove from storage
        const { data, error } = await supabase.from('g4y_device_file').delete().eq('id', id)
        if (error) throw error
        files.value = files.value.filter((p) => p.id !== id)
        if (files.value.length > 0) selectedFileId.value = files.value[0].id
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Datei wurde gelöscht', life: 3000 })
      }
    } catch (error) {
      console.trace(error)
      toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Datei konnte nicht vollständig gelöscht werden',
        life: 3000,
      })
    }
  }

  function enterDelete(id: number) {
    selectedFileId.value = id
    askDelete.value = true
  }

  function openFile(id: number) {
    const file = files.value.find((f) => f.id === id)
    if (file && file.url) window.open(file.url, '_blank')
  }
</script>
<style lang="scss">
  .p-progressbar {
    height: 0.55rem;
    .p-progressbar-value {
      background-color: var(--primary-color-700);
    }
  }

  #deleteIcon {
    font-size: 1.25rem;
    color: --primary-color-700;
    float: right;
    position: relative;
    top: -1.75rem;
    right: 0.5rem;
    &:hover {
      color: red;
    }
  }
</style>
