import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { router } from './router'

import * as Sentry from '@sentry/vue'

// PrimeVue
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import DatePicker from 'primevue/datepicker'
import OverlayPanel from 'primevue/overlaypanel'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import Dialog from 'primevue/dialog'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import SelectButton from 'primevue/selectbutton'
import Dropdown from 'primevue/dropdown'
import Layout from '@/components/Layout.vue'
import Textarea from 'primevue/textarea'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import ProgressSpinner from 'primevue/progressspinner'
import ProgressBar from 'primevue/progressbar'
import TreeSelect from 'primevue/treeselect'
import ConfirmDialog from 'primevue/confirmdialog'
import MultiSelect from 'primevue/multiselect'
import Toast from 'primevue/toast'
import OrderList from 'primevue/orderlist'
import RadioButton from 'primevue/radiobutton'
import Select from 'primevue/select'
import Tree from 'primevue/tree'
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import FloatLabel from 'primevue/floatlabel'
import Popover from 'primevue/popover'
import FileUpload from 'primevue/fileupload'
import Accordion from 'primevue/accordion'
import AccordionContent from 'primevue/accordioncontent'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import Fieldset from 'primevue/fieldset'

//@ts-ignore
import Aura from '@/presets/aura'
import 'primeicons/primeicons.css'

import '@/assets/styles/colors.scss'
import '@/assets/styles/g4y_theme.scss'
import '@/assets/styles/modal.scss'
import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://622c8ebb09d2a8c05739ae91ba9d763c@o4506931883737088.ingest.us.sentry.io/4507950241087488',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/[a-zA-z-]+\.sf-digital\.app/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(PrimeVue, {
  unstyled: true,
  pt: Aura,
  locale: {
    startsWith: 'startet mit',
    contains: 'enhält',
    notContains: 'enthält nicht',
    endsWith: 'endet mit',
    equals: 'gleich',
    notEquals: 'nicht gleich',
    noFilter: 'kein Filter',
    lt: 'weniger als',
    lte: 'weniger oder gleich wie',
    gt: 'größer als',
    gte: 'größer oder gleich wie',
    dateIs: 'Datum ist',
    dateIsNot: 'Datum ist nicht',
    dateBefore: 'Datum ist vor',
    dateAfter: 'Datum ist nach',
    custom: 'Benutzerdefiniert',
    apply: 'Übernehmen',
    matchAll: 'Passt auf alle',
    matchAny: 'Passt auf einige',
    addRule: 'Regel hinzufügen',
    removeRule: 'Regel entfernen',
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Auswählen',
    upload: 'Upload',
    cancel: 'Abbruch',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Löschen',
    weekHeader: 'Wo',
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yy',
    weak: 'Schwach',
    medium: 'Mittel',
    strong: 'Stark',
    passwordPrompt: 'Passwort eingeben',
    emptyFilterMessage: 'Keine Einträge gefunden',
    emptyMessage: 'Keine Einträge gefunden',
    aria: {
      trueLabel: 'Richtig',
      falseLabel: 'Falsch',
      nullLabel: 'Nicht selektiert',
      pageLabel: 'Seite',
      firstPageLabel: 'Erste Seite',
      lastPageLabel: 'Letzte Seite',
      nextPageLabel: 'Nächste Seite',
      previousPageLabel: 'Vorherige Seite',
    },
  },
})
app.use(router)
app.use(ToastService)
app.use(DialogService)
app.use(ConfirmationService)
app.use(pinia)

app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Button', Button)
app.component('SplitButton', SplitButton)
app.component('Dialog', Dialog)
app.component('OverlayPanel', OverlayPanel)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('Checkbox', Checkbox)
app.component('SelectButton', SelectButton)
app.component('Dropdown', Dropdown)
app.component('Layout', Layout)
app.component('Textarea', Textarea)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ProgressBar', ProgressBar)
app.component('TreeSelect', TreeSelect)
app.component('MultiSelect', MultiSelect)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)
app.component('OrderList', OrderList)
app.component('RadioButton', RadioButton)
app.component('Select', Select)
app.component('Tree', Tree)
app.component('Accordion', Accordion)
app.component('AccordionPanel', AccordionPanel)
app.component('AccordionHeader', AccordionHeader)
app.component('AccordionContent', AccordionContent)
app.component('FloatLabel', FloatLabel)
app.component('DatePicker', DatePicker)
app.component('Popover', Popover)
app.component('FileUpload', FileUpload)
app.component('Accordion', Accordion)
app.component('AccordionPanel', AccordionPanel)
app.component('AccordionHeader', AccordionHeader)
app.component('AccordionContent', AccordionContent)
app.component('Fieldset', Fieldset)

app.directive('tooltip', Tooltip)

app.mount('#app')
