<template>
  <div class="flex flex-col">
    <div v-for="date in serviceDates" :key="date.id">
      <span class="font-bold">Nächste {{ propertyStore.propertyTypeLabelById(date.property_type_id) }} fällig: </span>
      <span>{{ getFormattedValue(date) }}</span>
      <br />
      <span>Aktuelles Protokoll:</span>
      <ProtocolItem
        v-if="protMap && protMap.get(date.property_type_id)"
        :protocol="protMap.get(date.property_type_id)![0]"
        :use-new-button="true"
        :small="small"
        @edit-button-clicked="
          router.push({ path: '/maintenance', query: { edit: 'true', pid: protMap.get(date.property_type_id)![0].id } })
        "
        @new-button-clicked="
          router.push({
            path: '/maintenance',
            query: { new: 'true', id: asset.id, layout: protMap.get(date.property_type_id)![0].layout },
          })
        "
      />
      <div v-else class="flex flex-col justify-between rounded-md border-2 border-gray-400 p-1">
        <span>Kein Protokoll vorhanden</span>
        <Button
          v-if="rightsStore.isAllowed('CREATE', 'MAINTENANCE')"
          label="Protokoll anlegen"
          @click="
            router.push({
              path: '/maintenance',
              query: { new: 'true', id: asset.id, layout: getMissingProtocolLayout(date.property_type_id)?.id ?? 0 },
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useMaintenanceStore } from '@/store/maintenance'
  import { DeviceWithProperties, MaintenanceProtocol } from '@/types/extendDatabase'
  import { computed, Ref, ref, watch } from 'vue'
  import ProtocolItem from './ProtocolItem.vue'
  import { useRouter } from 'vue-router'
  import { getFormattedValue } from '@/helper/property'
  import { usePropertyStore } from '@/store/property'
  import { useRightsStore } from '@/store/rights'

  const props = defineProps({
    asset: {
      type: Object as () => DeviceWithProperties,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  })

  const maintenanceStore = useMaintenanceStore()
  const propertyStore = usePropertyStore()
  const rightsStore = useRightsStore()
  const router = useRouter()

  const deviceProtocols = computed(() =>
    maintenanceStore.getProtocolsByAsset(props.asset.id).filter((p) => ![3, 4].includes(p.status))
  )
  const protMap = ref(new Map<Number, MaintenanceProtocol[]>())
  const serviceDates: Ref<DeviceProperty[]> = computed(() =>
    props.asset.properties
      .filter((p) => propertyStore.propertyTypeById(p.property_type_id).data_type === 8)
      .sort((a, b) => (new Date(a.value_date ?? 0) < new Date(b.value_date ?? 0) ? -1 : 1))
  )
  fetchProtcols()
  watch(deviceProtocols, () => fetchProtcols())

  function fetchProtcols() {
    protMap.value.clear()
    deviceProtocols.value.forEach((p) => {
      const mapItem = protMap.value.get(p.layout)
      if (mapItem) {
        mapItem.push(p)
      } else {
        protMap.value.set(p.property_type_id, [p])
      }
    })
    protMap.value.forEach((val, key) => {
      val.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))
    })
  }

  function getMissingProtocolLayout(property_type_id: number) {
    return maintenanceStore.getLayoutByPropertyTypeId(property_type_id)
  }
</script>

<style scoped></style>
