import { supabase } from '@/supabase'
import { User } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
  let user: Ref<User | null> = ref(null)

  ;(async () => {
    user.value = (await supabase.auth.getUser()).data.user
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        if (session) {
          user.value = session.user
        }
      } else if (event === 'SIGNED_OUT') {
        user.value = null
      }
    })
  })()

  return { user }
})
