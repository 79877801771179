import { supabase } from '@/supabase'
import { MaintenanceProtocolLayout, MaintenanceProtocol } from '@/types/extendDatabase'
import { PostgrestError } from '@supabase/supabase-js'
import { defineStore } from 'pinia'
import { Ref, ref } from 'vue'
import { Tables } from '@/types/generatedDatabase.types'

export const useMaintenanceStore = defineStore('maintenanceStore', () => {
  const protocolLayouts: Ref<MaintenanceProtocolLayout[]> = ref([])
  const protocols: Ref<MaintenanceProtocol[]> = ref([])

  async function fetchLayouts() {
    try {
      const { data, error } = await supabase
        .from('maintenance_protocol_layout')
        .select(
          '*, properties:maintenance_layout_property_relation(position, maintenance_property_type(*)), checklist:maintenance_layout_checklistitem_relation(position, maintenance_checklistitem_type(*,maintenance_checklistitem_property_relation(position,maintenance_property_type(*))))'
        )
      if (error) throw error
      protocolLayouts.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchProtocols() {
    try {
      const { data, error } = await supabase
        .from('maintenance_protocol')
        .select(
          '*, layoutObj:maintenance_protocol_layout(*), properties: maintenance_protocol_property( *, maintenance_property_type(*,data_type(*))), checklist:maintenance_protocol_checklistitem(*,maintenance_checklistitem_type(*), maintenance_protocol_checklistitem_property(*, maintenance_property_type(*, data_type(*))))'
        )
        .not('status', 'in', '(3,4,6,8)')
      if (error) throw error
      data.forEach((p) => {
        p.properties.sort((a: any, b: any) => a.position - b.position)
        p.checklist.sort((a: any, b: any) => a.position - b.position)
        let checklistitemsArray: any[] = p.checklist
        checklistitemsArray.forEach((c) => {
          c.maintenance_protocol_checklistitem_property.sort((a: any, b: any) => a.position - b.position)
        })
      })
      protocols.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchSingleProtocol(id: number) {
    try {
      const { data, error } = await supabase
        .from('maintenance_protocol')
        .select(
          '*, layoutObj:maintenance_protocol_layout(*), properties: maintenance_protocol_property( *, maintenance_property_type(*,data_type(*))), checklist:maintenance_protocol_checklistitem(*,maintenance_checklistitem_type(*), maintenance_protocol_checklistitem_property(*, maintenance_property_type(*, data_type(*))))'
        )
        .eq('id', id)
        .single()
      if (error) throw error
      data.properties.sort((a: any, b: any) => a.position - b.position)
      data.checklist.sort((a: any, b: any) => a.position - b.position)
      let checklistitemsArray: any[] = data.checklist
      checklistitemsArray.forEach((c) => {
        c.maintenance_protocol_checklistitem_property.sort((a: any, b: any) => a.position - b.position)
      })
      let index = protocols.value.findIndex((p) => p.id === id)
      if (index != -1) protocols.value[index] = data
      else protocols.value.push(data)
    } catch (error: any) {
      console.error(error)
    }
  }

  ;(() => {
    fetchLayouts()
    fetchProtocols()
  })()

  async function insertProtocol(asset_id: number, layout_id: number): Promise<PostgrestError | null> {
    try {
      const layout = getLayout(layout_id)
      if (!layout) throw new Error('Layout nicht vorhanden')
      const newProtocol = {
        asset: asset_id,
        layout: layout_id,
        status: 2,
        label: layout.label,
        result_label: layout.result_label ?? 'Kein Label',
        property_type_id: layout.property_type_id,
        comment: '',
      }
      const { data: insertData, error: insertErrorProtocol } = await supabase
        .from('maintenance_protocol')
        .insert(newProtocol)
        .select()
        .single()
      if (insertErrorProtocol) throw insertErrorProtocol
      await insertProperties(
        insertData.id,
        layout.properties.map((p) => {
          return { id: p.maintenance_property_type!.id, pos: p.position! }
        })
      )
      await insertChecklistitems(insertData.id, layout.checklist)
      await fetchSingleProtocol(insertData.id)
      return null
    } catch (error: any) {
      console.error(error)
      return error
    }
  }

  async function insertProperties(protocol_id: number, property_type_ids: { id: number; pos: number }[]) {
    try {
      const newProperties = property_type_ids.map((p) => {
        return {
          protocol: protocol_id,
          property_type: p.id,
          position: p.pos,
        }
      })
      const { error } = await supabase.from('maintenance_protocol_property').insert(newProperties)
      if (error) throw error
    } catch (error: any) {
      console.error(error)
    }
  }

  async function insertChecklistitems(protocol_id: number, checklistitems: MaintenanceProtocolLayout['checklist']) {
    try {
      for (const item of checklistitems) {
        const { data, error } = await supabase
          .from('maintenance_protocol_checklistitem')
          .insert({
            protocol: protocol_id,
            checklistitem_type: item['maintenance_checklistitem_type']!.id,
            position: item.position,
          })
          .select()
          .single()
        if (error) throw error
        insertCheckListItemProperties(
          data.id,
          item.maintenance_checklistitem_type!.maintenance_checklistitem_property_relation.map((p) => {
            return { id: p.maintenance_property_type!.id, pos: p.position }
          })
        )
      }
    } catch (error: any) {
      console.error(error)
    }
  }

  async function insertCheckListItemProperties(
    checklistitem_id: number,
    property_type_ids: { id: number; pos: number }[]
  ) {
    try {
      const newProperties = property_type_ids.map((p) => {
        return {
          checklistitem: checklistitem_id,
          property_type: p.id,
          position: p.pos,
        }
      })
      const { error } = await supabase.from('maintenance_protocol_checklistitem_property').insert(newProperties)
      if (error) throw error
    } catch (error: any) {
      console.error(error)
    }
  }

  async function updateChecklistitemProperties(protocolId: number, values: any[]) {
    try {
      const { data, error } = await supabase.from('maintenance_protocol_checklistitem_property').upsert(values)
      if (error) throw error
      await fetchSingleProtocol(protocolId)
    } catch (error: any) {
      console.error(error)
    }
  }
  async function updateProperties(protocolId: number, values: any[]) {
    try {
      const { data, error } = await supabase.from('maintenance_protocol_property').upsert(values)
      if (error) throw error
      await fetchSingleProtocol(protocolId)
    } catch (error: any) {
      console.error(error)
    }
  }

  async function updateProtocolState(id: number, state: number, finishingProtocol: boolean) {
    let update: any = {
      status: state,
    }
    if (finishingProtocol) update.finished_at = new Date()

    try {
      const { data, error } = await supabase.from('maintenance_protocol').update(update).eq('id', id).single()
      if (error) throw error
      await fetchSingleProtocol(id)
    } catch (error: any) {
      console.error(error)
    }
  }

  async function updateProtocolComment(id: number, comment: string) {
    try {
      const { data, error } = await supabase
        .from('maintenance_protocol')
        .update({ comment: comment })
        .eq('id', id)
        .single()
      if (error) throw error
    } catch (error: any) {
      console.error(error)
    }
  }

  async function removeProtocol(id: number) {
    protocols.value = protocols.value.filter((p) => p.id !== id)
  }

  function getProtocol(protocol_id: number) {
    return protocols.value.find((p) => p.id === protocol_id)
  }

  function getLayout(layout_id: number) {
    return protocolLayouts.value.find((l) => l.id === layout_id)
  }

  function getLargestProtocolId() {
    return Math.max(...protocols.value.map((p) => p.id))
  }

  function getProtocolsByAsset(id: number) {
    return protocols.value.filter((p) => p.asset === id)
  }

  function getLayoutByPropertyTypeId(id: number) {
    return protocolLayouts.value.find((p) => p.property_type_id === id)
  }

  return {
    protocolLayouts,
    protocols,
    fetchLayouts,
    fetchProtocols,
    insertProtocol,
    getProtocol,
    getLayout,
    getLargestProtocolId,
    updateChecklistitemProperties,
    updateProtocolState,
    updateProtocolComment,
    updateProperties,
    removeProtocol,
    getProtocolsByAsset,
    getLayoutByPropertyTypeId,
  }
})
